<template>

  <div>

    <v-expansion-panels accordion focusable v-model="panel">

      <v-expansion-panel v-for="(category, index) in dataForm" style="border-radius: 8px;" class="text-primary mb-5">
        <v-expansion-panel-header class="today-medium" style="font-size: 1.5em;">
          {{ category.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row justify="center" class="pt-4">
            <v-col cols="12" md="12">
              <v-form
                  ref="form"
                  lazy-validation>

                <div v-for="(dataField, i) in category.fields">

                  <v-text-field
                      v-if="!['select_options', 'radio', 'title', 'separator', 'file', 'currency', 'checkbox_options'].includes(dataField.type)"
                      :type="dataField.type"
                      :label="dataField.name"
                      :value="dataField.value"
                      :rules="validationFields(dataField.validation)"
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                      v-on:blur="changeInput($event, dataField)"></v-text-field>

                  <!--                  <v-text-field
                                        v-if="['currency'].includes(dataField.type)"
                                        type="number"
                                        :label="dataField.name"
                                        :value="dataField.value"
                                        :rules="validationFields(dataField.validation)"
                                        outlined
                                        dense
                                        color="primary"
                                        class="input-field"
                                        v-on:blur="changeInput($event, dataField.id)"></v-text-field>-->

                  <currency-input v-if="['currency'].includes(dataField.type)"
                                  :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: true,
                                    autoDecimalDigits: false,
                                    autoSign: true,
                                    useGrouping: true,
                                    accountingSign: false
                                  }"
                                  v-on:input="changeInput($event, dataField)"
                                  :rules="validationFields(dataField.validation)"
                                  :label="dataField.name"
                                  :value="dataField.value"/>

                  <v-select
                      v-if="dataField.type == 'select_options' && dataField.data_source_description == null"
                      :items="dataField.options"
                      item-text='name'
                      item-value='key'
                      :label="dataField.name"
                      :value="dataField.value"
                      :rules="validationFields(dataField.validation)"
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                      v-on:change="changeSelect($event, dataField)"
                  ></v-select>

                  <v-select
                      v-if="dataField.type == 'select_options' && dataField.data_source_description != null"
                      :items="settingsDataSelect(dataField.data_source.split('.')[1], dataField)"
                      :item-text="dataField.data_source_description.label"
                      :item-value="dataField.data_source_description.value"
                      :label="dataField.name"
                      :value="dataField.value"
                      :rules="validationFields(dataField.validation)"
                      :id="''+dataField.id"
                      outlined
                      dense
                      color="primary"
                      class="select-field"
                      v-on:change="changeSelectNested($event, dataField)"
                  ></v-select>

                  <v-radio-group v-model="radioGroup" v-if="dataField.type == 'radio'">
                    <template v-slot:label>
                      <div>{{ dataField.name }}</div>
                    </template>
                    <v-radio
                        v-for="n in dataField.options"
                        :key="n.key"
                        :label="n.name"
                        :value="n"
                    ></v-radio>
                  </v-radio-group>

                  <v-file-input
                      v-if="dataField.type == 'file'"
                      :label="dataField.name"
                      outlined
                      dense
                      color="primary"
                  ></v-file-input>

                  <div v-if="dataField.type == 'checkbox_options'">
                    <v-checkbox
                        @change="changeInput($event, dataField)"
                        v-model="dataField.value"
                        v-for="n in dataField.options"
                        :key="n.key"
                        :label="n.name"
                        :value="n.name"
                    ></v-checkbox>
                  </div>

                  <v-divider v-if="dataField.type == 'separator'"></v-divider>

                  <div v-if="dataField.type == 'title'">
                    <v-card-title>{{ dataField.name }}</v-card-title>
                    <v-card-subtitle>{{ dataField.description }}</v-card-subtitle>
                  </div>

                  <div v-if="dataField.type == 'select_options' && dataField.data_source_description == null">
                    <div v-for="(selectField, i) in dataField.options">
                      <div v-if="dataField.value == selectField.key && selectField.fields.length > 0">

                        <div v-for="(dinamicField) in selectField.fields">
                          <v-text-field
                              v-if="!['select_options', 'radio', 'title', 'separator', 'file', 'currency'].includes(selectField.type)"
                              :type="dinamicField.type"
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="input-field"
                              v-on:blur="changeInput($event, dinamicField)"></v-text-field>

                          <v-text-field
                              v-if="['currency'].includes(dinamicField.type)"
                              type="number"
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="input-field"
                              v-on:change="changeInput($event, dinamicField)"

                              v-on:focus="toastTest('v-on:focus')"
                              v-on:focusout="toastTest('v-on:focusout')"></v-text-field>

                          <v-select
                              v-if="dinamicField.type == 'select_options' && dinamicField.data_source_description == null"
                              :items="dinamicField.options"
                              item-text='name'
                              item-value='key'
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              outlined
                              dense
                              color="primary"
                              class="select-field"
                              v-on:change="changeSelect($event, dinamicField)"
                          ></v-select>

                          <v-select
                              v-if="dinamicField.type == 'select_options' && dinamicField.data_source_description != null"
                              :items="settingsDataSelect(dinamicField.data_source.split('.')[1], dinamicField)"
                              :item-text="dinamicField.data_source_description.label"
                              :item-value="dinamicField.data_source_description.value"
                              :label="dinamicField.name"
                              :value="dinamicField.value"
                              :rules="validationFields(dinamicField.validation)"
                              :id="''+dinamicField.id"
                              outlined
                              dense
                              color="primary"
                              class="select-field"
                              v-on:change="changeSelectNested($event, dinamicField)"
                          ></v-select>

                          <v-radio-group v-model="radioGroup" v-if="dinamicField.type == 'radio'">
                            <template v-slot:label>
                              <div>{{ dinamicField.name }}</div>
                            </template>
                            <v-radio
                                v-for="n in dinamicField.options"
                                :key="n.key"
                                :label="n.name"
                                :value="n"
                            ></v-radio>
                          </v-radio-group>

                          <v-file-input
                              v-if="dinamicField.type == 'file'"
                              :label="dinamicField.name"
                              outlined
                              dense
                              color="primary"
                          ></v-file-input>

                          <v-divider v-if="dinamicField.type == 'separator'"></v-divider>

                          <div v-if="dinamicField.type == 'title'">
                            <v-card-title>{{ dinamicField.name }}</v-card-title>
                            <v-card-subtitle>{{ dinamicField.description }}</v-card-subtitle>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>

                </div>

              </v-form>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>


    <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>

</template>

<script>
import { locals } from "@/helpers/locals";
import { api } from "@/helpers/axios";
import { constants } from "@/helpers/constants";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  name: 'Fields',
  components: {CurrencyInput},
  props: ['dataForm'],
  data() {
    return {
      rules: {
        required: value => !!value || 'Campo obligatorio',
        counterMax: value => value.length <= 10 || 'Cantidad m�xima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos',
        email: value => /.+@.+/.test(value) || 'Formato de email invalido',
      },
      countries: null,
      countryZones: null,
      countryCities: null,
      settings: null,
      searchInput: '',
      request: null,
      snackbar: false,
      loading: false,
      snackbarMessage: '',
      validations: [],
      assets: 0,
      passives: 0,
      panel: 0
    }
  },
  created() {
    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');
    this.countries = this.settings.countries;
    this.countryZones = this.settings.country_zones;
    this.countryCities = this.settings.country_cities;
    //console.log(this.dataForm);
  },
  methods: {
    toastTest(typeEvent) {
      this.snackbarMessage = typeEvent;
      this.snackbar = true;
    },
    settingsDataSelect: function (data, fieldData) {

      // Validacion de datos para ver si tiene campos anidados
      let newSettingsDataSource = [];
      //console.log('validation 1: ', fieldData.related_field_id, fieldData.related_field_id, fieldData);
      if (fieldData.related_field_id != 0) {
        this.dataForm.forEach((category) => {
          category.fields.forEach((field) => {

            if (field.id == fieldData.related_field_id) {
              //console.log('settingsDataSelect', field, this.settings[field.data_source.split('.')[1]], field.id, fieldData.related_field_id);
              this.settings[field.data_source.split('.')[1]].forEach((dataSource1) => {
                //console.log('1: ', field.value, dataSource1.value, dataSource1);
                if (dataSource1.value == field.value) {
                  this.settings[data].forEach((settingsItem) => {
                    if (dataSource1.key == settingsItem.key) {
                      //console.log('2: ', dataSource1.key, settingsItem.key, settingsItem);
                      newSettingsDataSource.push(settingsItem);
                    }
                  });
                }
              });
            }

          });
        });
      }
      //console.log('return', newSettingsDataSource, this.settings[data], fieldData, newSettingsDataSource.length > 0 ? newSettingsDataSource : this.settings[data])
      return newSettingsDataSource.length > 0 ? newSettingsDataSource : this.settings[data];
    },
    validationFields(validation) {
      let validations = [];
      if (validation.search('required') != -1) {
        validations.push(this.rules.required);
      }
      if (validation.search('email') != -1) {
        validations.push(this.rules.email);
      }
      return validations;

    },
    changeInput(event, field) {
      // event.preventDefault();
      // this.toastTest('v-on:changeInput');
      // console.log('v-on:changeInput: ', event, field.id)
      let value = '';
      let validate = false;
      if (field.type == 'currency') {

        value = event;
        validate = true;

        // 39 -> Activos | 40 -> Pasivos
        if (field.id == 39) {
          this.assets = value;
        }
        if (field.id == 40) {
          this.passives = value;
        }

        if (this.passives > 0 && this.assets > 0) {
          this.total_assets = this.assets - this.passives;

          this.dataForm.forEach((category) => {
            category.fields.forEach((field) => {

              // 41 -> total patrimonio
              if (field.id == 41) {
                //console.log('total_assets', field, this.total_assets, this.assets, this.passives);
                field.value = this.total_assets.toString();
                this.saveField(this.total_assets, 41);
              }

            });
          });

        }

      } else if (event.target) {
        value = event.target.value;
        validate = event.target.validity.valid;
      } else {
        if (Array.isArray(event)) {
          value = event.join(', ');
        }
        validate = true;
      }

      if (value != '' && value != null && validate) {
        this.saveField(value, field.id);
      }
    },
    changeSelect(event, field) {
      let value = event;

      this.dataForm.forEach((category) => {
        category.fields.forEach((fieldData) => {
          if (fieldData.id == field.id) {
            fieldData.value = event;
          }
        });
      });

      if (value != '' && value != null) {
        this.saveField(value, field.id);
      }
    },
    changeSelectNested(event, field) {
      let value = event;
      if(value != '' && value != null) {
        this.saveField(value, field.id);
      }

      this.dataForm.forEach((category) => {
        category.fields.forEach((fieldData) => {
          if (fieldData.id == field.id) {
            fieldData.value = event;
          }
        });
      });


    },
    saveField(value, fieldId) {
      api.post(constants.endPoints.saveForm, {
        field_id: fieldId,
        user_request_id: this.request.id,
        value: value
      }, true)
          .then(response => {
            console.log('saveForm', response);

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            //console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log('catch: ', e);
          //locals.clearLocal();
          //this.$router.push('/');
        }

      }.bind(this))
    }
  }
}
</script>

<style>

.v-expansion-panel-header:before {
  background-color: #00205B !important;
}

</style>
