<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-mediumum">
                    P�liza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Formulario
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="10" class="scroll-container">

              <Fields :dataForm="dataForm" ></Fields>

<!--              <v-expansion-panels accordion focusable>

                <v-expansion-panel v-for="(category, index) in dataForm" style="border-radius: 8px;" class="mb-5">
                  <v-expansion-panel-header class="primary&#45;&#45;text today-medium" style="font-size: 1.5em;">
                    {{ category.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row justify="center" class="pt-4">
                      <v-col cols="12" md="12">
                        <v-form
                            ref="form"
                            lazy-validation>

                          <div v-for="(dataField, i) in category.fields" >

                            <Fields :dataForm="dataForm" ></Fields>

                          </div>

                        </v-form>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>-->


            </v-col>

          </v-row>
          <v-layout justify-center pt-5>
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="goSignatureDocuments"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>


        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import Fields from "@/components/Form/Fields";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'Form',
  components: {LeftSideImage, NavBarPage, Fields},
  data() {
    return {
      payDialog: false,
      registerCellphoneDialog: false,
      loadingCellphone: false,
      loading: false,
      codeSent: false,
      firstFeeValue: 0,
      creditStudy: 15000,
      insurance: 2365,
      ivaValue: 2143,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 10000,
      totalValuePolicy: 3500000,
      discountValue: 175716,
      valueFinance: 3324284,
      feeNumber: 2,
      rate: 1.25,
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      dataForm: null,
      snackbar: false,
      snackbarMessage: ''
    }
  },
  mounted() {
  },
  methods: {

    goSignatureDocuments() {
      // CONSUMIR EL SERVICIO DE VALIDACION DE FORMULARIO

      api.post(constants.endPoints.validateFullForm, {
        user_request_id: this.request.id
      }, true)
        .then(response => {

          this.$router.push('/firma-documentos');

        }).catch(function (error) {
        //

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))

    },

    registerCellphone() {
      this.registerCellphoneDialog = true;
    },
    sendCellphone: function () {
      this.loadingCellphone = true;

      // Se inicia la cuenta atras
      this.startCountDown();

      setTimeout(function () {
        this.loadingCellphone = false;
        this.codeSent = true;
      }.bind(this), 1000)
    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,

      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          //clearInterval(this.interval);
          this.expires_in = 60;
        } else {
          this.expires_in -= 1;
          this.countdown = this.expires_in;
        }
      }, 1000);
    },
    goNext(event){
      this.$refs.digit2.focus()
    }
  },
  created: function() {
    this.request = locals.getLocal('request');
    api.post(constants.endPoints.form, {
      user_request_id: this.request.id
    }, true)
        .then(response => {
          this.dataForm = response.data.data.form.map(cat => {
            return {
              ...cat,
              fields: cat.fields.map(field => {
                return {
                  ...field,
                  value: field.type === 'checkbox_options' ? field.value.split(', ') : ''
                }
              })
            }
          });
        }).catch(function (error) {

      try {
        let status = error.response.status;
        if(status == 401){
          locals.clearLocal();
          this.$router.push('/');
        }
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;
      } catch (e) {
        console.log(e);
        locals.clearLocal();
        this.$router.push('/');
      }

    }.bind(this))

    /*api.get(constants.endPoints.form, true)
      .then(response => {
        console.log('form', response);
        this.dataForm = response.data.data.form;
      }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))*/
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>
